@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: "Noto Sans Georgian", sans-serif;
}

@layer base {
    body {
        @apply bg-dark-bg;
    }
}

.pattaya {
    font-family: "Pattaya", sans-serif;
}

/* https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* removes default view password icon on browsers */
input[type="password"]::-ms-reveal {
    display: none;
}

/* removes default clear password icon on browsers */
input[type="password"]::-ms-clear {
    display: none;
}